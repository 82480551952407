import Button from "@components/Button";
import { VariantButton } from "@shopcashTypes/variantButton";
import Link from "next/link";
import styles from "./Error.module.scss";

interface ErrorProps {
  errorCode: number;
  errorMsg: string;
}

const Error: React.FC<ErrorProps> = ({ errorCode, errorMsg }) => {
  return (
    <>
      {errorCode == 404 && (
        <div className={styles.header}>
          <Link href="/">
            <a className={styles.logo}>
              <img src="https://assets.wego.com/image/upload/w_280/v1603273048/web/shopcash/shopcash-logo.png" />
            </a>
          </Link>
        </div>
      )}
      <div className={styles.container}>
        <img
          className={styles.image}
          src="https://assets.wego.com/image/upload/w_400/v1604479049/web/shopcash/illustrations-no-results.png"
        />
        <p className={styles.msg}>{errorMsg}</p>
      </div>
      {errorCode == 404 && (
        <div className={styles.button}>
          <Link href="/">
            <a>
              <Button
                variant={VariantButton.Primary}
                fullWidth={true}
                large={true}
              >
                Return to Homepage
              </Button>
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

export default Error;
