import Error from "@components/Error";
import { NextPage } from "next";
import { useRouter } from "next/router";

// do not import the whole translation json files because it is too big
const errorMessage = {
  en: "Oh no! Page not found", // manual copy from en.json["404error"]
  ar: "خلل رقم 404", // manual copy from ar.json["404error"]
};

const Custom404: NextPage = () => {
  const router = useRouter();

  return (
    <>
      <Error
        errorCode={404}
        errorMsg={errorMessage[router.locale.includes("ar") ? "ar" : "en"]}
      />
    </>
  );
};

export default Custom404;
